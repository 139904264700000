import PersonAddIcon from '@mui/icons-material/PersonAdd';
import WidgetsIcon from '@mui/icons-material/Widgets';
import CategoryIcon from '@mui/icons-material/Category';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ListIcon from '@mui/icons-material/List';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import WebIcon from '@mui/icons-material/Web';

const SidebarData = [
    {
        icon: <AssessmentIcon />,
        title: "Sales Report",
        path: "/"
    },
    {
        icon: <PersonAddIcon />,
        title: "Add User",
        path: "/add-user/"
    },
    {
        icon: <WebIcon />,
        title: "Landing Page",
        path: "/update-landing-page/"
    },
    {
        icon: <CategoryIcon />,
        title: "Add Category",
        path: "/add-category/"
    },
    {
        icon: <WidgetsIcon />,
        title: "Add Subcategory",
        path: "/add-subcategory/"
    },
    {
        icon: <ListIcon />,
        title: "Add Items",
        path: "/add-items/"
    },
    {
        icon: <LibraryAddIcon />,
        title: "Add Options",
        path: "/add-options/"
    },
    {
        icon: <ReceiptIcon />,
        title: "New Orders",
        path: "/new-orders/"
    },
    {
        icon: <ReceiptLongIcon />,
        title: "Invoices",
        path: "/invoices/"
    },
    {
        icon: <CheckCircleIcon />,
        title: "Completed Orders",
        path: "/completed-orders/"
    },
    {
        icon: <ExitToAppIcon />,
        title: "Logout",
        path: "/login/"
    },
]

export const userToken = "L9dGe1z1WVjOkulGD5JYrbRUVXLiOAC9otqDodLaCJNNFEaIdL";

export default SidebarData;