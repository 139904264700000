import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState, useEffect, useRef, useContext } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import DocTitle from "../components/DocTitle";
import DocAlert from "../components/DocAlert";
import { Breadcrumbs, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import PrintIcon from '@mui/icons-material/Print';
import Invoice from "../components/Invoice";
import httpClient from "../httpClient";
import commonContext from "../contexts/common/commonContext";
import { mobileNo } from "../data/constants";

const CompletedOrders = () => {
  const navigate = useNavigate();
  const { compOrdersLen, setCompOrdersLen, user } = useContext(commonContext);
    
    useEffect(() => {
        if(user===null) {
            navigate('/login/');
        }
    }, [user, navigate]);

  const [CompletedOrdersData, setCompletedOrdersData] = useState([]);
  const [FilteredData, setFilteredData] = useState([]);

  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");

  const [itemEdit, setItemEdit] = useState(false);
  const [viewItem, setViewItem] = useState(null);
  const [viewItemTotalQuantity, setViewItemTotalQuantity] = useState(0);
  const [viewItemTotalAmount, setViewItemTotalAmount] = useState(0);
  const [viewItemOrders, setViewItemOrders] = useState([]);

  const [alertCont, setAlertCont] = useState("");
  const [alertError, setAlertError] = useState(0);
  const [filtered, setFiltered] = useState(false);

  const [pageSize, setPageSize] = useState(10);

  const [invOpen, setInvOpen] = useState(false);
  const handleInvOpen = () => setInvOpen(true);
  const handleInvClose = () => {
    setInvOpen(false);
    httpClient.put('/ordersData/' + viewItem.id, {
      date: viewItem.date,
      location: viewItem.location,
      name: viewItem.name,
      mobile: viewItem.mobile,
      address: viewItem.address,
      orders: viewItemOrders,
      type: viewItem.type,
      fop: viewItem.fop
    });
  };

  var compRef = useRef();

  const columns = [
    {
      field: "srno",
      headerName: "#",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "id",
      headerName: "Order ID",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Order Date",
      headerAlign: "center",
      align: "left",
      width: 200
    },
    {
      field: "total",
      headerName: "Total",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let amount = 0;
        for (let i=0; i<params.row.orders.length; i++) {
          amount += params.row.orders[i].price * params.row.orders[i].quantity;
        }
        return <div>{(amount).toFixed(2)}</div>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      headerAlign: "center",
      align: "center",
    },
    { field: "fop", headerName: "FOP", headerAlign: "center", align: "center" },
    {
      field: "view",
      headerName: "View",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className="table-view-button">
            <button onClick={() => handleView(params.row)}>View</button>
          </div>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className="table-delete-button">
            <button onClick={() => handleDelete(params.row.id)}>Cancel</button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    httpClient.get('/compOrdersData')
      .then(res => {
          let tmp = [];
          if(res.data) {
              [...res.data].reverse().forEach((ordr, index) => {
                  ordr.srno = index+1;
                  tmp.push(ordr);
              })
          }
          setCompletedOrdersData(tmp);
          setFilteredData(tmp);
      });
  }, [alertError]);

  useEffect(() => {
    let quantity = 0, amount = 0;
    for (let i=0; i < viewItemOrders.length; i++) {
      quantity += Number(viewItemOrders[i].quantity);
      amount += Number(viewItemOrders[i].price) * Number(viewItemOrders[i].quantity);
    }
    setViewItemTotalQuantity(quantity);
    setViewItemTotalAmount(amount);
  }, [viewItemOrders]);

  const handleView = (item) => {
    setViewItem(item);
    handleInvOpen();
    setViewItemOrders(item.orders);
    setItemEdit(false);
  };

  const handleDelete = (id) => {
    httpClient.delete('/compOrdersData/' + id)
      .then(() => {
        setCompOrdersLen(compOrdersLen - 1);
        setAlertCont("Order Cancelled Successfully!!");
        setAlertError(2);
      })
      .catch(() => {
        setAlertCont("Failed to Cancel!!");
        setAlertError(1);
      });

    setTimeout(() => {
      setAlertError(0);
    }, 2000);
  };

  const handleViewItemEdit = () => {
    setItemEdit(true); 
    setViewItemOrders(viewItem.orders);
  }

  const handleViewItemQuantityEdit = (index, value) => {
    const orders = [...viewItem.orders];
    orders[index].quantity = value===""? 0 : Number(value);
    setViewItemOrders(orders);
  }

  const handleViewItemRateEdit = (index, value) => {
    const orders = [...viewItem.orders];
    orders[index].price = value===""? 0 : Number(value);
    setViewItemOrders(orders);
  }

  const handleViewItemEditDone = () => {
    setItemEdit(false);
    
    httpClient.put('/compOrdersData/' + viewItem.id, {
      date: viewItem.date,
      location: viewItem.location,
      name: viewItem.name,
      mobile: viewItem.mobile,
      address: viewItem.address,
      orders: viewItemOrders,
      type: viewItem.type,
      fop: viewItem.fop
    });
  }

  const isValidDate = (date) => {
    let dateformat = /^\d{4}[-](0?[1-9]|1[0-2])[-](0?[1-9]|[1-2][0-9]|3[01])$/;

    if (date.match(dateformat)) {
      let operator = date.split("-");

      let datepart = [];
      if (operator.length > 1) {
        datepart = date.split("-");
      }
      let month = parseInt(datepart[0]);
      let day = parseInt(datepart[1]);
      let year = parseInt(datepart[2]);

      let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return false;
        }
      } else if (month === 2) {
        let leapYear = false;
        if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
        if (leapYear === false && day >= 29) return false;
        else {
          if (leapYear === true && day > 29) {
            console.log("Invalid date format!");
            return false;
          }
        }
      }
    } else {
      return false;
    }
    return true;
  };

  const isValidRange = (date1, date2) => {
    date1 = new Date(date1);
    date2 = new Date(date2);
    if (date1 > date2) return false;
    return true;
  };

  const isDateBetween = (date1, date2, date3) => {
    date1 = new Date(date1);
    date2 = new Date(date2);
    date3 = new Date(date3);
    if (date1 <= date2 && date2 <= date3) return true;
    return false;
  };

  const handleSearchForm = (e) => {
    e.preventDefault();
    if (!isValidDate(date1) || !isValidDate(date2)) {
      setAlertError(1);
      setAlertCont("Enter a valid date!!");
      setTimeout(() => {
        setAlertError(0);
      }, 2000);
    } else if (!isValidRange(date1, date2)) {
      setAlertError(1);
      setAlertCont("Dates should be in Chronological order!!");
      setTimeout(() => {
        setAlertError(0);
      }, 2000);
    } else {
      let tmp = [];
      for (let i = 0; i < CompletedOrdersData.length; i++) {
        const curDate = CompletedOrdersData[i].date.split(" ")[0];
        if (!isValidRange(date1, curDate)) break;
        if (isDateBetween(date1, curDate, date2)) {
          tmp.push(CompletedOrdersData[i]);
        }
      }
      setFilteredData(tmp);
      setFiltered(true);
    }
  };

  const handleClearSearch = () => {
    setFilteredData(CompletedOrdersData);
    setFiltered(false);
  };

  return (
    <>
      {alertError > 0 ? (
        <DocAlert alertCont={alertCont} alertError={alertError} />
      ) : (
        <Breadcrumbs className="breadcrumb">
          <Link to="/" className="breadcrumb-active-link">
            Home
          </Link>
          <Link to="/completed-orders/">Completed Orders</Link>
        </Breadcrumbs>
      )}
      <DocTitle title="Completed Orders" />
      <section id="completed-orders">
        <div className="search-form">
          <form onSubmit={handleSearchForm}>
            <input
              type="text"
              placeholder="FROM: YYYY-MM-DD"
              value={date1}
              onChange={(e) => setDate1(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="TO: YYYY-MM-DD"
              value={date2}
              onChange={(e) => setDate2(e.target.value)}
              required
            />
            <button
              type="submit"
              className="search-submit-btn"
              disabled={filtered ? true : false}
            >
              Search
            </button>
            {filtered && (
              <button onClick={handleClearSearch}>Clear Search</button>
            )}
          </form>
        </div>

        <div className="table-section">
          <div className="section-header">
            <p>View Completed Orders</p>
          </div>

          <div className="table-div">
            <div className="completed-orders-table">
              <DataGrid
                columns={columns}
                rows={FilteredData}
                components={{ Toolbar: GridToolbar }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50, 100]}
                sx={{
                  ".MuiDataGrid-columnHeaders": {
                    backgroundColor: "var(--white-color-3)",
                    fontSize: "1.1em",
                  },
                  ".MuiDataGrid-virtualScroller": {
                    backgroundColor: "var(--white-color-1)",
                    fontSize: "1.1em",
                  },
                  ".MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell":
                    {
                      alignItems: "flex-start",
                      whiteSpace: "normal",
                      overflow: "auto",
                      padding: "5px",
                    },
                }}
                disableSelectionOnClick
              />
            </div>
          </div>
        </div>

        <Modal
          open={invOpen}
          onClose={handleInvClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="view-invoice-modal">
            <div className="space-bet-div">
              <p>Invoice</p>
              <div className="space-bet-div">
                <div className="edit-icon-div">
                  <IconButton onClick={!itemEdit? handleViewItemEdit : handleViewItemEditDone}>
                    {!itemEdit? (
                      <EditIcon className="edit-icon"/>
                    ):(
                      <DoneIcon />
                    )}
                  </IconButton>
                </div>
                { !itemEdit && 
                  <div id="print-content">
                    <ReactToPrint
                      trigger={() => (
                        <IconButton>
                          <PrintIcon />
                        </IconButton>
                      )}
                      content={() => compRef.current}
                    />
                    <div
                      style={{
                        position: "absolute",
                        height: "0",
                        width: "0",
                        display: "none",
                      }}
                    >
                      <div ref={compRef} id="view-inv">
                        <Invoice 
                          viewItem={viewItem} 
                          orders={viewItemOrders} 
                          totalQuantity={viewItemTotalQuantity} 
                          totalAmount={viewItemTotalAmount} 
                          user={user} 
                        />
                      </div>
                    </div>
                  </div>
                }
                <div className="modal-close-icon-div">
                  <IconButton onClick={handleInvClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </div>
            {viewItem && (
              <div className="view-invoice-content">
                <div className="invoice-header-img">
                  <img src="../../../../kumail_banner.png" alt="" />
                </div>
                <p className="tel">Tel: {mobileNo} | Head Office</p>
                <div className="invoice-content">
                  <div className="cust-details">
                    <div className="space-bet-div">
                      <h3>{viewItem.date.split(" ")[0]}</h3>
                      <h3>Order#{viewItem.id}</h3>
                    </div>
                    <h3>{viewItem.date.split(" ").slice(1).join(" ")}</h3>
                    <h3 className="div-head">Delivery</h3>
                    <h3>Name: {viewItem.name}</h3>
                    <h3>Mobile: {viewItem.mobile}</h3>
                    <h3>Address: {viewItem.address}</h3>
                  </div>
                  <div className="cust-details">
                    <div className="space-bet-div item-details">
                      <div>
                        <h3>ITEM</h3>
                      </div>
                      <div className="space-bet-div item-attr">
                        <h3>QTY</h3>
                        <h3>RATE</h3>
                        <h3>AMT</h3>
                      </div>
                    </div>
                  </div>
                  <div className="cust-details">
                    {viewItemOrders.map((order, index) => (
                      <div className="space-bet-div specific-item" key={index}>
                        <div className="specific-item-title">
                          <h3>{order.pid} - {order.name}</h3>
                        </div>
                        <div className="specific-item-attr">
                          {itemEdit? (
                              <div className="edit-input">
                                <input id={`quantityInput-${index}`} type="number" min="1" value={order.quantity} onChange={(e) => handleViewItemQuantityEdit(index, e.target.value)} required/>
                              </div>
                            ): (
                            <h3>{order.quantity}</h3>
                          )}
                          {itemEdit? (
                              <div className="edit-input">
                                <input id={`priceInput-${index}`} type="number" min="1" value={order.price} onChange={(e) => handleViewItemRateEdit(index, e.target.value)} required/>
                              </div>
                            ): (
                            <h3>{order.price}</h3>
                          )}
                          <h3>{(order.quantity * order.price).toFixed(2)}</h3>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="space-bet-div total-div">
                    <div>
                      <h3>NOS QTY: {viewItemTotalQuantity}</h3>
                    </div>
                    <div>
                      <h3>TOTAL: {viewItemTotalAmount.toFixed(2)} KWD</h3>
                    </div>
                  </div>
                  <div className="admin-div">
                    <h3>FOP: {viewItem.fop}</h3>
                    <h3>WAITER: {user}</h3>
                  </div>
                  <div className="cust-details thank-you-div">
                    <h3>Thank you Come Again!</h3>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </section>
    </>
  );
};

export default CompletedOrders;
