export const mobileNo = "+965 9957 0613";

export const categoryFolderNames = {
  'Accessories'          : 'accessories',
  'Connectors'           : 'connectors',
  'Door Bell'            : 'door_bells',
  'Fans'                 : 'fans',
  'Home Appliances'      : 'home_appliances',
  'Insect Killer'        : 'insect_killers',
  'Iron'                 : 'iron',
  'Lamps'                : 'lamps',
  'Lights'               : 'lights',
  'Plugs and Extensions' : 'plugs_n_exts',
  'Switches'             : 'switches',
  'Trimmers'             : 'trimmers',
  'Trunkey'              : 'trunkey',
  'Wires'                : 'wires',
  'All'                  : 'mixed-categories'
}

export const getCategoryFolderName = (cat) => {
  if (categoryFolderNames[cat]===undefined) {
    return cat;
  } else {
    return categoryFolderNames[cat];
  }
}