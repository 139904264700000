import React from "react";
import { Routes, Route } from "react-router-dom";
import NewOrders from "../pages/NewOrders";
import AddCategory from "../pages/AddCategory";
import AddSubcategory from "../pages/AddSubcategory";
import AddItem from "../pages/AddItems";
import AddUser from "../pages/AddUser";
import AddOptions from "../pages/AddOptions";
import Login from "../pages/Login";
import SalesReport from "../pages/SalesReport";
import Invoices from "../pages/Invoices";
import CompletedOrders from "../pages/CompletedOrders";
import UpdateLandingPage from "../pages/UpdateLandingPage";

const RouterRoutes = () => {

    return (
        <>
            <Routes>
                <Route path="/" element={<SalesReport />}/>
                <Route path="/new-orders" element={<NewOrders />}/>
                <Route path="/add-category" element={<AddCategory />}/>
                <Route path="/add-subcategory" element={<AddSubcategory />}/>
                <Route path="/update-landing-page" element={<UpdateLandingPage />}/>
                <Route path="/add-items" element={<AddItem />}/>
                <Route path="/add-options" element={<AddOptions />}/>
                <Route path="/add-user" element={<AddUser />}/>
                <Route path="/invoices" element={<Invoices />}/>
                <Route path="/completed-orders" element={<CompletedOrders />}/>
                <Route path="/login/" element={<Login />}/>

            </Routes>
        </>
    );
};

export default RouterRoutes;
