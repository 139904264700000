import { IconButton, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DocTitle from "../components/DocTitle";
import DocAlert from "../components/DocAlert";
import commonContext from "../contexts/common/commonContext";
import httpClient from "../httpClient";
import { userToken } from "../data/SidebarData";

const Login = () => {

    const { setUser } = useContext(commonContext);

    const [UserData, setUserData] = useState([]);

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const [alertCont, setAlertCont] = useState("");
    const [alertError, setAlertError] = useState(0);
    const [logging, setLogging] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    
    useEffect(() => {
        localStorage.clear();
        httpClient.get('/userData')
        .then(res => {            
            setUserData(res.data);
        })
    }, []);
    

    const handleSubmit = (e) => {
        e.preventDefault();
        setLogging(true);
        var userFound = false;
        var passFound = false;
        var userInd = -1;
        for(let i=0; i < UserData.length; i++) {
            if(userName===UserData[i].username) {
                userFound = true;
                if(password===UserData[i].password)
                    passFound = true;
            }
            if(userFound && passFound) {
                userInd = i;
                break;
            }
        }

        setTimeout(() => setLogging(false), 1000);

        if(userFound && passFound) {
            setAlertError(2);
            setAlertCont("Welcome back!!");
        }
        else if(userFound) {
            setAlertError(1);
            setAlertCont("Incorrect Password!!");
        }
        else {    
            setAlertError(1);
            setAlertCont("Invalid Credentials!!");
        }

        setTimeout(() => {
            setAlertError(0);
        }, 2000);

        if(userFound && passFound) {
            setTimeout(() => {
                setUser(UserData[userInd].username);
                localStorage.setItem("userToken", userToken);
                localStorage.setItem("username", UserData[userInd].username);
                navigate("/");
            }, 1500);
        }

    }

    
    return (
        <div id="login">
            { alertError > 0 && <DocAlert alertCont={alertCont} alertError={alertError} />}
            <div id="login-page">
                <div id="bg"></div>
                <div className="login-div">
                    <div className="login-div-bg"></div>
                    <div className="banner">
                        <img src="/kumail_banner.png" alt="logo.png"/>
                    </div>
                    <div style={{zIndex: 10, position: "absolute", top: "30px"}}>
                        <DocTitle title="Admin Panel" />
                        <form onSubmit={handleSubmit} className="form">
                            <TextField id="username-input" label="Username" variant="outlined" sx={{
                                width: "100%", 
                                margin: "10px 0"
                            }}
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                autoComplete="current-username"
                            />
                            <FormControl variant="outlined" id="psswd-input" sx={{
                                width: "100%", 
                                margin: "10px 0"
                                }}>
                                <InputLabel htmlFor="passwd">Password</InputLabel>
                                <OutlinedInput
                                    id="passwd"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    label="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    autoComplete="current-password"
                                />
                            </FormControl>
                            {!logging && <button type="submit">LOGIN</button> }
                            {logging && <button type="submit" className="active-btn" disabled>LOGGING IN...</button> }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;