import { IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import commonContext from "../contexts/common/commonContext";
import { useContext } from "react";
import { Link } from "react-router-dom";
import LanguageIcon from '@mui/icons-material/Language';

const Navbar = () => {

  const { toggleSidebar, isSidebarOpen } = useContext(commonContext);

  return (
    <div id="navbar">
      <div className="sidebar-toggle-btn">
          <IconButton onClick={() => toggleSidebar(!isSidebarOpen)}>
              <MenuIcon />
          </IconButton>
      </div>


      <div>
        <Link to="https://kumail.shop" target="_blank" className="web-link">
          <h3 className="icon"><LanguageIcon /></h3>
          <h3> Kumail</h3>
        </Link>
      </div>

    </div>
  );
};

export default Navbar;