import { createContext, useReducer, useEffect } from 'react';
import commonReducer from './commonReducer';
import httpClient from '../../httpClient';
import { userToken } from '../../data/SidebarData';


// Common-Context
const commonContext = createContext();

// Initial State
const initialState = {
    isSidebarOpen: false,
    user: (localStorage.length > 0 && localStorage.userToken && localStorage.userToken===userToken)? localStorage.username : null,
    newOrdersLen: 0,
    invoicesLen: 0,
    compOrdersLen: 0
};

// Common-Provider Component
const CommonProvider = ({ children }) => {

    useEffect(() => {
        httpClient.get("/ordersData")
        .then(res => {
          initialState.newOrdersLen =  res.data.length;
        });
    
        httpClient.get("/invoicesData")
        .then(res => {
          initialState.invoicesLen = res.data.length;
        });

        httpClient.get("/compOrdersData")
        .then(res => {
          initialState.compOrdersLen = res.data.length;
        });
      }, []);

    const [state, dispatch] = useReducer(commonReducer, initialState);

    // Search actions
    const toggleSidebar = (toggle) => {
        return dispatch({
            type: 'TOGGLE_SIDEBAR',
            payload: { toggle }
        });
    };

    const setUser = (toggle) => {
        return dispatch({
            type: 'SET_USER',
            payload: { toggle }
        })
    }

    const removeUser = () => {
        return dispatch({
            type: 'REMOVE_USER'
        })
    }

    const setNewOrdersLen = (toggle) => {
        return dispatch({
            type: 'SET_NEWORDERS_LEN',
            payload: { toggle }
        })
    }

    const setInvoicesLen = (toggle) => {
        return dispatch({
            type: 'SET_INVOICES_LEN',
            payload: { toggle }
        })
    }

    const setCompOrdersLen = (toggle) => {
        return dispatch({
            type: 'SET_COMP_ORDERS_LEN',
            payload: { toggle }
        })
    }

    // Context values
    const values = {
        ...state,
        toggleSidebar,
        setUser,
        removeUser,
        setNewOrdersLen,
        setInvoicesLen,
        setCompOrdersLen
    };

    return (
        <commonContext.Provider value={values}>
            {children}
        </commonContext.Provider>
    );
};

export default commonContext;
export { CommonProvider };