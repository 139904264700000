import { IconButton } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from "react";

const DocAlert = ({alertCont, alertError}) => {

    const [cont, setCont] = useState(alertCont);
    const [err, setErr] = useState(alertError);
    
    const clearAlert = () => {
        setErr(0);
        setCont("");
    }

    return (
        <div id="alert-div">
            {(err!==0)? (
                 <div className="alert-content"
                     style={{
                         color: `${err===1? "rgba(100,0,0)":"rgba(0,100,0)"}`, 
                         backgroundColor: `${err===1? "rgba(255,150,150)":"rgba(150,200,150)"}`,
                         borderBottom: `1px solid ${err===1? "rgba(100,0,0)":"rgba(0,100,0)"}`
                     }}>
                     {cont}
                     <IconButton onClick={clearAlert} className="clr-btn">
                         <ClearIcon className="clr-icon"/>
                     </IconButton>
                 </div>
     
             ) : (
                 <></>
             )}
        </div>
        
    )
}

export default DocAlert;