import { mobileNo } from "../data/constants";

const Invoice = ({viewItem, user}) => {

    if(viewItem) {

        const orders = viewItem.orders;
        let totalQuantity = 0, totalAmount = 0;
        for (let i=0; i < orders.length; i++) {
            totalQuantity += Number(orders[i].quantity);
            totalAmount += Number(orders[i].price) * Number(orders[i].quantity);
        }

        return (
            <div className="view-invoice-content">
                <p id="invoice-header">Invoice</p>
                <div className="invoice-header-img">
                <img src="../../../../kumail_banner.png" alt="" />
                </div>
                <p className="tel">Tel: {mobileNo} | Head Office</p>
                <div className="invoice-content">
                <div className="cust-details">
                    <div className="space-bet-div">
                    <h1>{viewItem.date.split(" ")[0]}</h1>
                    <h1>Order#{viewItem.id}</h1>
                    </div>
                    <h1>{viewItem.date.split(" ").slice(1).join(" ")}</h1>
                    <h1 className="div-head">Delivery</h1>
                    <h1>Name: {viewItem.name}</h1>
                    <h1>Mobile: {viewItem.mobile}</h1>
                    <h1>Address: {viewItem.address}</h1>
                </div>
                <div className="cust-details">
                    <div className="space-bet-div item-details">
                    <div>
                        <h1>ITEM</h1>
                    </div>
                    <div className="space-bet-div item-attr">
                        <h1>QTY</h1>
                        <h1>RATE</h1>
                        <h1>AMT</h1>
                    </div>
                    </div>
                </div>
                <div className="cust-details">
                    {orders.map((order, index) => (
                        <div className="space-bet-div specific-item" key={index}>
                            <div className="specific-item-title">
                            <h1>{order.pid} - {order.name}</h1>
                            </div>
                            <div className="specific-item-attr">
                            <h1>{order.quantity}</h1>
                            <h1>{order.price}</h1>
                            <h1>{order.quantity * order.price}</h1>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="space-bet-div total-div">
                    <div>
                    <h1>NOS QTY: {totalQuantity}</h1>
                    </div>
                    <div>
                    <h1>TOTAL: {(totalAmount).toFixed(3)} KWD</h1>
                    </div>
                </div>
                <div className="admin-div">
                    <h1>FOP: {viewItem.fop}</h1>
                    <h1>WAITER: {user}</h1>
                </div>
                <div className="cust-details thank-you-div">
                    <h1>Thank you Come Again!</h1>
                </div>
                </div>
            </div>
        )
    }

    else {
        return <></>;
    }

}

export default Invoice;
