import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState, useEffect, useContext } from "react";
import Modal from '@mui/material/Modal';
import DocTitle from "../components/DocTitle";
import DocAlert from "../components/DocAlert";
import { Breadcrumbs, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from "react-router-dom";
import commonContext from "../contexts/common/commonContext";
import httpClient from "../httpClient";

const AddCategory = () => {

    const navigate = useNavigate();

    const { user } = useContext(commonContext);
    
    useEffect(() => {
        if(user===null) {
            navigate('/login/');
        }
    }, [user, navigate]);

    const [CategoryData, setCategoryData] = useState([]);

    const [category, setCategory] = useState("");
    const [priority, setPriority] = useState(0);
    const [description, setDescription] = useState("");

    const [alertCont, setAlertCont] = useState("");
    const [alertError, setAlertError] = useState(0);
    const [adding, setAdding] = useState(false);

    const [chId, setChId] = useState(0);
    const [chCategory, setChCategory] = useState("");
    const [chPriority, setChPriority] = useState(0);
    const [chDescription, setChDescription] = useState("");

    const [pageSize, setPageSize] = useState(10);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    
    const columns = [
        {field: "srno", headerName: "#", headerAlign: "center", align: "center", width: 100},
        {field: "category", headerName: "CATEGORY", headerAlign: "center", align: "left", width: 120},
        {field: "priority", headerName: "PRIORITY", headerAlign: "center", align: "center", width: 100},
        {field: "edit", headerName: "EDIT", headerAlign: "center", align: "center", width: 100, 
            renderCell: (params) => {
                return (
                    <div className="table-edit-button">
                        <button onClick={() => handleEdit(params.row)}>Edit</button>
                    </div>
                )
            }
        },
        {field: "delete", headerName: "DELETE", headerAlign: "center", align: "center", width: 100,
            renderCell: (params) => {
                return (
                    <div className="table-delete-button">
                        <button onClick={() => handleDelete(params.row.id)}>Delete</button>
                    </div>
                )
            }
        },
    ];
    
    useEffect(() => {
        httpClient.get('/categoryData')
            .then(res => {
                // console.log(res);
                let tmp = [];
                if(res.data) {
                    res.data.forEach((cat, index) => {
                        cat.srno = index+1;
                        tmp.push(cat);
                    })
                }
                setCategoryData(tmp);
            });
    }, [alertError]);


    const handleSubmit = (event) => {
        event.preventDefault();
        let found = CategoryData.filter((item) => item.category.toLowerCase()===category.toLowerCase());
        if(found.length!==0) {
            setAlertCont("Category already exists!!");
            setAlertError(1);
        }
        else {
            setAdding(true);
            const categoryName = category[0].toUpperCase() + category.slice(1).toLowerCase();
            httpClient.post("/categoryData", {
                category: categoryName,
                priority: priority,
                description: description
            })
            .then(() => {
                setAlertCont("New Category Added!!");
                setAlertError(2);
                setAdding(false);
                setCategory("");
                setPriority("");
                setDescription("");
            })
            .catch(() => {
                setAlertCont("Failed to Add!!");
                setAlertError(1);
                setAdding(false);
            });
        }
        setTimeout(() => {
            setAlertError(0);
        }, 2000);
    };


    const handleEdit = (item) => {
        setChId(item.id);
        setChCategory(item.category);
        setChPriority(item.priority);
        setChDescription(item.description);

        handleOpen();
    }

    const handleModalForm = (e) => {
        e.preventDefault();
        httpClient.put('/categoryData/' + chId, {
            category: chCategory,
            priority: chPriority,
            description: chDescription
        })
        .then(() => {
            setAlertCont("Successfully Updated!!");
            setAlertError(2);
        })
        .catch(() => {
            setAlertCont("Failed to Update!!");
            setAlertError(1);
        });
        handleClose();
        
        setTimeout(() => {
            setAlertError(0);
        }, 2000);
    }


    const handleDelete = (id) => {
        httpClient.delete("/categoryData/" + id)
        .then(() => {
            setAlertCont("Succesfully Deleted!!");
            setAlertError(2);
        })
        .catch(() => {
            setAlertCont("Failed to Delete!!");
            setAlertError(1);
        });
        handleClose();

        setTimeout(() => {
            setAlertError(0);
        }, 2000);
    }


    return (
        <>
            { alertError > 0 ? 
                (<DocAlert alertCont={alertCont} alertError={alertError} />) : (
                    <Breadcrumbs className="breadcrumb">
                        <Link to="/" className="breadcrumb-active-link">Home</Link>
                        <Link to="/add-category">Category</Link>
                    </Breadcrumbs>
                )}
            <DocTitle title="Category"/>
            <section id="add-category">
                <div className="category-form">
                    <form onSubmit={handleSubmit} className="form">
                        <div className="form-group">
                            <label htmlFor="cat-name">Category</label>
                            <input type="text" name="cat-name" id="cat-name" value={category} placeholder="Category Name" onChange={(e) => setCategory(e.target.value)} required/>
                            <div className="input-helper">Enter atleast 4 characters</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="cat-priority">Priority</label>
                            <input type="number" name="cat-priority" id="cat-priority" min="0" value={priority} onChange={(e) => setPriority(e.target.value)} required/>
                            <div className="input-helper">Select priority ranging from 0</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="cat-desc">Description</label>
                            <textarea name="cat-desc" id="cat-desc" rows="5" value={description} placeholder="Describe something about it..." onChange={(e) => setDescription(e.target.value)} required></textarea>
                            <div className="input-helper">Describe the category</div>
                        </div>
                        { !adding && <button type="submit">Add</button>}
                        { adding && <button type="submit" className="active-btn" disabled>Adding...</button>}
                    </form>
                </div>
                
                <div className="category-table">
                    <DataGrid
                        columns={columns}
                        rows={CategoryData}
                        components={{Toolbar: GridToolbar}}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10,25,50,100]}
                        sx={{
                            ".MuiDataGrid-columnHeaders" : {
                                backgroundColor: "var(--white-color-3)",
                                fontSize: "1.1em",
                            },
                            ".MuiDataGrid-virtualScroller" : {
                                backgroundColor: "var(--white-color-1)",
                                fontSize: "1.1em",
                            },
                            ".MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell": {
                                alignItems: "flex-start",
                                whiteSpace: "normal",
                                overflow: "auto",
                                padding: "5px"
                            }
                        }}
                        disableSelectionOnClick
                    />
                </div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="category-modal-updation-form">
                        <div className="modal-close-icon-div">
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <form onSubmit={handleModalForm} className="form">
                            <div className="form-group">
                                <label htmlFor="ch-cat-name">Category Name</label>
                                <input type="text" name="ch-cat-name" id="ch-cat-name" value={chCategory} onChange={(e) => setChCategory(e.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="ch-cat-priority">Priority</label>
                                <input type="number" name="ch-cat-priority" id="ch-cat-priority" min="0" value={chPriority} onChange={(e) => setChPriority(e.target.value)} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="ch-cat-priority">Description</label>
                                <textarea name="ch-cat-desc" id="ch-cat-desc" rows="5" value={chDescription} onChange={(e) => setChDescription(e.target.value)} required></textarea>
                            </div>
                            <button type="submit">Update</button>
                        </form>
                    </div>
                </Modal>

            </section>
        </>
    )
};

export default AddCategory;