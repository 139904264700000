import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState, useEffect, useContext } from "react";
import Modal from '@mui/material/Modal';
import DocTitle from "../components/DocTitle";
import DocAlert from "../components/DocAlert";
import { Breadcrumbs, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate } from "react-router-dom";
import httpClient from "../httpClient";
import commonContext from "../contexts/common/commonContext";

const AddSubcategory = () => {

    const navigate = useNavigate();

    const { user } = useContext(commonContext);
    
    useEffect(() => {
        if(user===null) {
            navigate('/login/');
        }
    }, [user, navigate]);

    const [CategoryData, setCategoryData] = useState([]);
    const [SubcategoryData, setSubcategoryData] = useState([]);

    const [subcategory, setSubcategory] = useState("");
    const [priority, setPriority] = useState(0);
    const [category, setCategory] = useState("");

    const [alertCont, setAlertCont] = useState("");
    const [alertError, setAlertError] = useState(0);
    const [adding, setAdding] = useState(false);

    const [chId, setChId] = useState(0);
    const [chSubcategory, setChSubcategory] = useState("");
    const [chPriority, setChPriority] = useState(0);
    const [chCategory, setChCategory] = useState("");

    const [pageSize, setPageSize] = useState(10);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const columns = [
        {field: "srno", headerName: "#", headerAlign: "center", width: 100, align: "center"},
        {field: "subcategory", headerName: "SUBCATEGORY", headerAlign: "center", width: 120, align: "left"},
        {field: "priority", headerName: "PRIORITY", headerAlign: "center", width: 100, align: "center"},
        {field: "edit", headerName: "EDIT", headerAlign: "center", align: "center", width: 100, 
            renderCell: (params) => {
                return (
                    <div className="table-edit-button">
                        <button onClick={() => handleEdit(params.row)}>Edit</button>
                    </div>
                )
            }
        },
        {field: "delete", headerName: "DELETE", headerAlign: "center", align: "center", width: 100, 
            renderCell: (params) => {
                return (
                    <div className="table-delete-button">
                        <button onClick={() => handleDelete(params.row.id)}>Delete</button>
                    </div>
                )
            }
        },
    ];
    
    useEffect(() => {
        httpClient.get('/categoryData')
            .then(res => {
                // console.log(res);
                setCategoryData(res.data);
            });

        httpClient.get('/subcategoryData')
            .then(res => {
                // console.log(res);
                let tmp = [];
                if(res.data) {
                    res.data.forEach((subcat, index) => {
                        subcat.srno = index+1;
                        tmp.push(subcat);
                    })
                }
                setSubcategoryData(tmp);
            });
        
    }, [alertError]);


    const handleSubmit = (event) => {
        event.preventDefault();
        let found = SubcategoryData.filter((item) => item.subcategory.toLowerCase()===subcategory.toLowerCase());
        if(found.length!==0) {
            setAlertCont("Subcategory already exists!!");
            setAlertError(1);
        }
        else {
            setAdding(true);
            const subcategoryName = subcategory[0].toUpperCase() + subcategory.slice(1).toLowerCase();
            httpClient.post('/subcategoryData', {
                subcategory: subcategoryName, 
                priority: priority, 
                category: category
            })
            .then(() => {
                setAlertCont("New Subcategory Added!!");
                setAlertError(2);
                setAdding(false);
                setCategory("");
                setSubcategory("");
                setPriority("");
            })
            .catch(() => {
                setAlertCont("Failed to Add!!");
                setAlertError(1);
                setAdding(false);
            });
        }
        setTimeout(() => {
            setAlertError(0);
        }, 2000);
    };


    const handleEdit = (item) => {
        setChId(item.id);
        setChSubcategory(item.subcategory);
        setChPriority(item.priority);
        setChCategory(item.category);

        handleOpen();
    }

    const handleModalForm = (e) => {
        e.preventDefault();
        httpClient.put('/subcategoryData/' + chId, {
            subcategory: chSubcategory, 
            priority: chPriority, 
            category: chCategory
        })
        .then(() => {
            setAlertCont("Successfully Updated!!");
            setAlertError(2);
        })
        .catch(() => {
            setAlertCont("Failed to Update!!");
            setAlertError(1);
        });
        handleClose();
        
        setTimeout(() => {
            setAlertError(0);
        }, 2000);
    }


    const handleDelete = (id) => {
        httpClient.delete('/subcategoryData/' + id)
        .then(() => {
            setAlertCont("Succesfully Deleted!!");
            setAlertError(2);
        })
        .catch(() => {
            setAlertCont("Failed to Delete!!");
            setAlertError(1);
        });
        handleClose();

        setTimeout(() => {
            setAlertError(0);
        }, 2000);
    }


    return (
        <>
            { alertError > 0 ? 
                (<DocAlert alertCont={alertCont} alertError={alertError} />) : (
                    <Breadcrumbs className="breadcrumb">
                        <Link to="/" className="breadcrumb-active-link">Home</Link>
                        <Link to="/add-subcategory">Subcategory</Link>
                    </Breadcrumbs>
                )}

            <DocTitle title="Sub-Category"/>

            <section id="add-subcategory">
                <div className="subcategory-form">
                    <form onSubmit={handleSubmit} className="form">
                        <div className="form-group">
                            <label htmlFor="subcat-name">Subcategory</label>
                            <input type="text" name="subcat-name" id="subcat-name" value={subcategory} placeholder="Subcategory Name" onChange={(e) => setSubcategory(e.target.value)} required/>
                            <div className="input-helper">Enter atleast 4 characters</div>
                        </div>
                        <div className="form-group select-input-grp">
                            <label htmlFor="cat-name">Category</label>
                            <select id="cat-name" name="cat-name" value={category} onChange={(e) => setCategory(e.target.value)} required>
                                <option value="" disabled>Select Category</option>
                                { CategoryData.map((item,index) => (
                                    <option value={item.category} key={index}>{item.category}</option>
                                )) }
                            </select>
                            <div className="input-helper">Select the category</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="subcat-priority">Priority</label>
                            <input type="number" name="subcat-priority" id="subcat-priority" min="0" value={priority} onChange={(e) => setPriority(e.target.value)} required/>
                            <div className="input-helper">Select priority ranging from 0</div>
                        </div>
                        { !adding && <button type="submit">Add</button>}
                        { adding && <button type="submit" className="active-btn" disabled>Adding...</button>}
                    </form>
                </div>
                
                <div className="subcategory-table">
                    <DataGrid
                        columns={columns}
                        rows={SubcategoryData}
                        components={{Toolbar: GridToolbar}}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10,25,50,100]}
                        sx={{
                            ".MuiDataGrid-columnHeaders" : {
                                backgroundColor: "var(--white-color-3)",
                                fontSize: "1.1em",
                            },
                            ".MuiDataGrid-virtualScroller" : {
                                backgroundColor: "var(--white-color-1)",
                                fontSize: "1.1em",
                            },
                            ".MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell": {
                                alignItems: "flex-start",
                                whiteSpace: "normal",
                                overflow: "auto",
                                padding: "5px"
                            }
                        }}
                        disableSelectionOnClick
                    />
                </div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="subcategory-modal-updation-form">
                        <div className="modal-close-icon-div">
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <form onSubmit={handleModalForm} className="form">
                            <div className="form-group">
                                <label htmlFor="subcat-name">Subcategory Name</label>
                                <input type="text" name="subcat-name" id="subcat-name" value={chSubcategory} onChange={(e) => setChSubcategory(e.target.value)} required/>
                            </div>
                            <div className="form-group select-input-grp">
                                <label htmlFor="cat-name">Category Name</label>
                                <select id="cat-name" name="cat-name" value={chCategory} onChange={(e) => setChCategory(e.target.value)} required>
                                    <option value="select-cat" disabled>Select Category</option>
                                    { CategoryData.map((item,index) => (
                                        <option value={item.category} key={index}>{item.category}</option>
                                    )) }
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="subcat-priority">Priority</label>
                                <input type="number" name="subcat-priority" id="subcat-priority" min="0" value={chPriority} onChange={(e) => setChPriority(e.target.value)} required/>
                            </div>
                            <button type="submit">Update</button>
                        </form>
                    </div>
                </Modal>

            </section>
        </>
    )
};

export default AddSubcategory;