import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState, useEffect, useContext } from "react";
import Modal from '@mui/material/Modal';
import DocTitle from "../components/DocTitle";
import DocAlert from "../components/DocAlert";
import { Breadcrumbs, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import httpClient from "../httpClient";
import commonContext from "../contexts/common/commonContext";

const AddUser = () => {

    const navigate = useNavigate();
    const { user } = useContext(commonContext);
    
    useEffect(() => {
        if(user===null) {
            navigate('/login/');
        }
    }, [user, navigate]);

    const [UserData, setUserData] = useState([]);

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    
    const [alertCont, setAlertCont] = useState("");
    const [alertError, setAlertError] = useState(0);
    const [adding, setAdding] = useState(false);
    
    const [chId, setChId] = useState(0);
    const [chUserName, setChUserName] = useState("");
    const [chPassword, setChPassword] = useState("");

    const [pageSize, setPageSize] = useState(10);
    const [show, setShow] = useState(false);
    const [chShow, setChShow] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);    
    
    const columns = [
        {field: "srno", headerName: "#", headerAlign: "center", align: "center"},
        {field: "username", headerName: "USER", headerAlign: "center", align: "left"},
        {field: "edit", headerName: "EDIT", headerAlign: "center", align: "center",
            renderCell: (params) => {
                return (
                    <div className="table-edit-button">
                        <button onClick={() => handleEdit(params.row)}>Edit</button>
                    </div>
                )
            }
        },
        {field: "delete", headerName: "DELETE", headerAlign: "center", align: "center", 
            renderCell: (params) => {
                return (
                    <div className="table-delete-button">
                        <button onClick={() => handleDelete(params.row.id)}>Delete</button>
                    </div>
                )
            }
        },
    ];
    
    useEffect(() => {
        httpClient.get('/userData')
        .then(res => {
            let tmp = [];
            if(res.data) {
                res.data.forEach((user, index) => {
                    user.srno = index+1;
                    tmp.push(user);
                })
            }
            setUserData(tmp);
        });

    }, [alertError]);


    const handleSubmit = (event) => {
        event.preventDefault();
        let found = UserData.filter((item) => item.username.toLowerCase()===userName.toLowerCase());
        if(found.length!==0) {
            setAlertCont("User already exists!!");
            setAlertError(1);
        }
        else {
            setAdding(true);
            const finalUserName = userName[0].toUpperCase() + userName.slice(1).toLowerCase();
            httpClient.post('/userData', {
                username: finalUserName,
                password: password
            })
            .then(() => {
                setAlertCont("New User Added!!");
                setAlertError(2);
                setAdding(false);
                setUserName("");
                setPassword("");
            })
            .catch(() => {
                setAlertCont("Failed to Add!!");
                setAlertError(1);
                setAdding(false);
            });
        }
        setTimeout(() => {
            setAlertError(0);
        }, 2000);
    };


    const handleEdit = (item) => {
        setChId(item.id);
        setChUserName(item.username);
        setChPassword(item.password);
        setChShow(false);

        handleOpen();
    }

    const handleModalForm = (e) => {
        e.preventDefault();
        httpClient.put("/userData/" + chId, {
                username: chUserName,
                password: chPassword
            })
            .then(() => {
                setAlertCont("Successfully Updated!!");
                setAlertError(2);
            })
            .catch(() => {
                setAlertCont("Failed to Update!!");
                setAlertError(1);
            });
        handleClose();
        
        setTimeout(() => {
            setAlertError(0);
        }, 2000);
    }


    const handleDelete = (id) => {

        if(UserData.length===1) {
            setAlertCont("Can't be deleted!! Atleast one user has to be logged in!!");
            setAlertError(1);
        }

        else {
            httpClient.delete('/userData/' + id)
            .then(() => {
                setAlertCont("Succesfully Deleted!!");
                setAlertError(2);
            })
            .catch(() => {
                setAlertCont("Failed to Delete!!");
                setAlertError(1);
            });
    
        }
        setTimeout(() => {
            setAlertError(0);
        }, 2000);

    }      


    return (
        <>
            { alertError > 0 ? 
                (<DocAlert alertCont={alertCont} alertError={alertError} />) : (
                    <Breadcrumbs className="breadcrumb">
                        <Link to="/" className="breadcrumb-active-link">Home</Link>
                        <Link to="/add-user">User</Link>
                    </Breadcrumbs>
                )}
            <DocTitle title="User"/>
            <section id="add-user">
                <div className="user-form">
                    <form onSubmit={handleSubmit} className="form" encType="multipart/form-data">
                        <div className="form-group">
                            <label htmlFor="user-name">User</label>
                            <input type="text" name="user-name" id="user-name" value={userName} placeholder="User Name" onChange={(e) => setUserName(e.target.value)} autoComplete="current-username" required/>
                            <div className="input-helper">Enter atleast 4 characters</div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="passwd">Password</label>
                            <div className="passwd-input">
                                <input type={show? "text" : "password"} name="passwd" id="passwd" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" required/>
                                <IconButton onClick={() => setShow(!show)}>{show? <VisibilityOff /> : <Visibility />}</IconButton>
                            </div>
                            <div className="input-helper">Password should not be easy...</div>
                        </div>
                        
                        { !adding && <button type="submit">Add</button>}
                        { adding && <button type="submit" className="active-btn" disabled>Adding...</button>}
                    </form>
                </div>
                
                <div className="user-table">
                    <DataGrid
                        columns={columns}
                        rows={UserData}
                        components={{Toolbar: GridToolbar}}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10,25,50,100]}
                        sx={{
                            ".MuiDataGrid-columnHeaders" : {
                                backgroundColor: "var(--white-color-3)",
                                fontSize: "1.1em",
                            },
                            ".MuiDataGrid-virtualScroller" : {
                                backgroundColor: "var(--white-color-1)",
                                fontSize: "1.1em",
                            },
                            ".MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell": {
                                alignItems: "flex-start",
                                whiteSpace: "normal",
                                overflow: "auto",
                                padding: "5px"
                            }
                        }}
                        disableSelectionOnClick
                    />
                </div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="user-modal-updation-form">
                        <div className="modal-close-icon-div">
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <form onSubmit={handleModalForm} className="form">
                            <div className="form-group">
                                <label htmlFor="user-name">User</label>
                                <input type="text" name="user-name" id="user-name" value={chUserName} placeholder="User Name" onChange={(e) => setChUserName(e.target.value)} autoComplete="current-username" required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="passwd">Password</label>
                                <div className="passwd-input">
                                    <input type={chShow? "text" : "password"} name="passwd" id="passwd-1" value={chPassword} placeholder="Password" onChange={(e) => setChPassword(e.target.value)} autoComplete="current-password" required/>
                                    <IconButton onClick={() => setChShow(!chShow)}>{chShow? <VisibilityOff /> : <Visibility />}</IconButton>
                                </div>
                            </div>
                            <button type="submit">Update</button>
                        </form>
                    </div>
                </Modal>
            </section>
        </>
    )
};

export default AddUser;