import RouterRoutes from './routes/RouterRoutes';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import commonContext from './contexts/common/commonContext';
import { useContext } from 'react';
import useMediaQuery from "@mui/material/useMediaQuery";

function App() {

  const { isSidebarOpen, user } = useContext(commonContext);
  const isNonMobile = useMediaQuery("(min-width: 868px)");

  return (
      <div className='app' style={{backgroundColor: `${user===null? "var(--white-color-1)":"var(--bg-light-1)"}`}}>
        { !(user===null) && <Sidebar /> }
        <div className='app-content'  style={{width: "100%", marginLeft: `${!(user===null) && isNonMobile && isSidebarOpen? "280px": "0"}`}}>
          { !(user===null) && <Navbar /> }
          <RouterRoutes />
        </div>
      </div>
  );
}

export default App;
