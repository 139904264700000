import React, { useEffect, useState } from "react";
import Modal from '@mui/material/Modal';
import DocTitle from "../components/DocTitle";
import DocAlert from "../components/DocAlert";
import { Breadcrumbs, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import httpClient from "../httpClient";
import { Link } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';

const UpdateLandingPage = () => {

    const [ heroProducts, setHeroProducts ] = useState([]);
    const [ featuredProducts, setFeaturedProducts ] = useState([]);
    const [ topCategories, setTopCategories ] = useState([]);

    // eslint-disable-next-line
    const [alertCont, setAlertCont] = useState("");
    // eslint-disable-next-line
    const [alertError, setAlertError] = useState(0);

    const [ itemName, setItemName ] = useState("");
    const [ itemCategory, setItemCategory ] = useState("");
    const [ itemSubcategory, setItemSubcategory ] = useState("");
    const [ itemPrice, setItemPrice ] = useState("");
    const [ itemDescription, setItemDescription ] = useState("");

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleClick = (item) => {
        setItemName(item.title);
        setItemCategory(item.category);
        setItemSubcategory(item.subcategory);
        setItemPrice(item.finalPrice);
        setItemDescription(item.description);
        handleOpen();
    }

    // const handleAddHeroProducts = (item) => {
    //     let f = true;
    //     for(let i=0; i < heroProducts.length; i++) {
    //         if(heroProducts.id===item.id) {
    //             f = false;
    //             setAlertCont("Product Already Exists!");
    //             setAlertError(1);
    //         }
    //     }

    //     if(f) {
    //         httpClient.post('/heroNFeaturedProductsData', {
    //             id: item.id,
    //             title: item.title,
    //             category: item.category,
    //             subcategory: item.subcategory,
    //             finalPrice: Number(item.finalPrice),
    //             originalPrice: Number(item.originalPrice),
    //             description: item.description,
    //             rateCount: parseInt(item.rate),
    //             images: item.images,
    //             options: item.options,
    //         })
    //         .then(() => {
    //             setAlertCont("Successfully Added!!");
    //             setAlertError(2);
    //         })
    //         .catch(() => {
    //             setAlertCont("Failed to Add!!");
    //             setAlertError(1);
    //         });
    //         setTimeout(() => {
    //             setAlertError(0);
    //         }, 2000);
    //     }
    // }


    useEffect(() => {
        httpClient.get('/landingPageData')
        .then((res) => {
            setHeroProducts(res.data.heroProducts);
            setFeaturedProducts(res.data.featuredProducts);
            setTopCategories(res.data.topCategories);
        });
    }, []);

    return (
        <>
            { alertError > 0 ? 
                (<DocAlert alertCont={alertCont} alertError={alertError} />) : (
                    <Breadcrumbs className="breadcrumb">
                        <Link to="/" className="breadcrumb-active-link">Home</Link>
                        <Link to="/update-landing-page">Landing Page</Link>
                    </Breadcrumbs>
                )}

            <DocTitle title="Hero Products"/>
            <ul className="item-container">
                { heroProducts.map((item, index) => (
                    <li key={index} className="item" onClick={() => handleClick(item)}>
                        <p>{item.id} - {item.title}</p>
                        <div className="delete-icon">
                            <DeleteIcon />
                        </div>
                    </li>
                ))}
                { heroProducts.length < 4 && 
                    <li className="add-item-div">
                        <input placeholder="Enter Product ID" type="number" min="1" />
                        <button style={{marginLeft: "10px"}}>Add</button>
                    </li>
                }
            </ul>

            <DocTitle title="Featured Products"/>
            <ul className="item-container">
                { featuredProducts.map((item, index) => (
                    <li key={index} className="item" onClick={() => handleClick(item)}>
                        <p>{item.id} - {item.title}</p>
                        <div className="delete-icon">
                            <DeleteIcon />
                        </div>
                    </li>
                ))}
                { featuredProducts.length < 6 && 
                    <li className="add-item-div">
                        <input placeholder="Enter Product ID" type="number" min="1" />
                        <button style={{marginLeft: "10px"}}>Add</button>
                    </li>
                }
            </ul>


            <DocTitle title="Top Categories"/>
            <ul className="item-container" style={{marginBottom: "10rem"}}>
                { topCategories.map((item, index) => (
                    <li key={index} className="item">
                        <p>{item.category}</p>
                        <div className="delete-icon">
                            <DeleteIcon />
                        </div>
                    </li>
                ))}
            </ul>
            {topCategories.length < 5 && 
                <div>
                    <input placeholder="Enter the Category" />
                    <li className="add-item">Add</li>
                </div>
            }

            <Modal
                open={open}
                onClose={handleClose}
            >
                <div className="product-modal">
                    <div className="modal-close-icon-div">
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className="item-content">
                        <h3>Product</h3>
                        <p><b>Title:</b> {itemName}</p>
                        <p><b>Category:</b> {itemCategory}</p>
                        <p><b>Subcategory:</b> {itemSubcategory}</p>
                        <p><b>Price:</b> KWD {itemPrice}</p>
                        <p><b>Description:</b> {itemDescription}</p>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default UpdateLandingPage;
