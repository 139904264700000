import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState, useEffect, useContext } from "react";
import Modal from '@mui/material/Modal';
import DocTitle from "../components/DocTitle";
import DocAlert from "../components/DocAlert";
import { Breadcrumbs, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import httpClient from "../httpClient";
import commonContext from "../contexts/common/commonContext";


const AddOptions = () => {
    
    const navigate = useNavigate();
    const { user } = useContext(commonContext);
    
    useEffect(() => {
        if(user===null) {
            navigate('/login/');
        }
    }, [user, navigate]);

    const [productsData, setProductsData] = useState([]);

    const [editItem, setEditItem] = useState(null);
    const [itemId, setItemId] = useState(0);
    const [itemName, setItemName] = useState("");
    const [category, setCategory] = useState("");
    const [subcategory, setSubcategory] = useState("");
    const [salePrice, setSalePrice] = useState(0);
    const [orgPrice, setOrgPrice] = useState(0);
    const [options, setOptions] = useState([]);
    
    const [alertCont, setAlertCont] = useState("");
    const [alertError, setAlertError] = useState(0);

    const [newOption, setNewOption] = useState("");
    
    const [pageSize, setPageSize] = useState(10);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const columns = [
        {field: "srno", headerName: "#", headerAlign: "center", width: 100, align: "center"},
        {field: "title", headerName: "ITEM", headerAlign: "center", width: 120, align: "left"},
        {field: "category", headerName: "CATEGORY", headerAlign: "center", width: 100, align: "center"},
        {field: "finalPrice", headerName: "SALE PRICE", headerAlign: "center", width: 100, align: "center"},
        {field: "options", headerName: "OPTIONS", headerAlign: "center", align: "center", width: 100,  
            renderCell: (params) => {
                return (
                    <div className="table-edit-button">
                        <button onClick={() => handleEdit(params.row)}>Edit</button>
                    </div>
                )
            }
        }
    ];
    
    useEffect(() => {

        httpClient.get('/allProductsData')
            .then(res => {
                let tmp1 = [];
                let tmp2 = [];
                if(res.data) {
                    res.data.forEach((itm) => {
                        if(itm.category==="All"){
                            tmp1.push(itm);
                        } else {
                            tmp2.push(itm);
                        }
                    });
                    setProductsData(tmp2.concat(tmp1).map((itm, ind) => ({
                        ...itm,
                        srno: ind + 1
                    })));
                }
            });
        
    }, [alertError]);


    const handleEdit = (item) => {
        setEditItem(item);
        setItemId(item.id);
        setItemName(item.title);
        setCategory(item.category);
        setSubcategory(item.subcategory);
        setSalePrice(item.finalPrice);
        setOrgPrice(item.originalPrice);
        setOptions(item.options);
        setNewOption("");
        handleOpen();
    }

    const handleAddOption = (e) => {
        e.preventDefault();
        let notfound = options===undefined || options===null || (options.filter((opt) => opt[0].toLowerCase()===newOption.toLowerCase()).length===0);
        if(!notfound) {
            setAlertCont("Option already exists!!");
            setAlertError(1);
        }
        else {
            const finalOptionName = newOption[0].toUpperCase() + newOption.slice(1).toLowerCase();
            let newItem = editItem;
            if(newItem.options===undefined || newItem.options===null) {
                newItem.options = [[finalOptionName, []]];
            }
            else {
                newItem.options.push([finalOptionName, []]);
            }

            httpClient.put('/allProductsData/' + itemId, {
                images: newItem.images,
                title: newItem.title,
                category: newItem.category,
                subcategory: newItem.subcategory,
                finalPrice: newItem.finalPrice,
                originalPrice: newItem.originalPrice,
                rateCount: newItem.rateCount,
                options: newItem.options,
                path: newItem.path,
                tag: newItem.tag,
                tagline: newItem.tagline
            })
            .then(() => {
                setNewOption("");
                setAlertCont("New Option Added!!");
                setAlertError(2);
            })
            .catch(() => {
                setAlertCont("Failed to Add!!");
                setAlertError(1);
            });
        }
        setTimeout(() => {
            setAlertError(0);
        }, 2000);
        handleClose();
    }


    const Option = ({opt}) => {
    
        const [newSuboption, setNewSuboption] = useState("");    
        
        const handleDeleteOption = () => {
            let newItem = editItem;
            let opts = [];
            options.forEach((option) => {
                if(opt[0]!==option[0]) {
                    opts.push(option);
                }
            });
            newItem.options = opts;
            
            httpClient.put('/allProductsData/' + itemId, {
                images: newItem.images,
                title: newItem.title,
                category: newItem.category,
                subcategory: newItem.subcategory,
                finalPrice: newItem.finalPrice,
                originalPrice: newItem.originalPrice,
                rateCount: newItem.rateCount,
                options: newItem.options,
                path: newItem.path,
                tag: newItem.tag,
                tagline: newItem.tagline
            })
            .then(() => {
                setAlertCont("Option Deleted Successfully!!");
                setAlertError(2);
            })
            .catch(() => {
                setAlertCont("Failed to Delete!!");
                setAlertError(1);
            });
            setTimeout(() => {
                setAlertError(0);
            }, 2000);
            handleClose();
        }


        const handleAddSuboption = (e) => {
            e.preventDefault();
            let newItem = editItem;
            let f = true;
            for(let i=0; i<editItem.options.length; i++) {
                if(opt[0]===editItem.options[i][0]) {
                    if(newItem.options[i][1].indexOf(newSuboption)===-1){
                        if(newSuboption.length > 1) {
                            const finalSubOptionName = newSuboption[0].toUpperCase() + newSuboption.slice(1).toLowerCase();
                            newItem.options[i][1].push(finalSubOptionName);
                        }
                        else {
                            newItem.options[i][1].push(newSuboption);
                        }
                    }
                    else {
                        f = false;
                    }
                    break;
                }
            };
            if(f) {
                httpClient.put('/allProductsData/' + itemId, {
                    images: newItem.images,
                    title: newItem.title,
                    category: newItem.category,
                    subcategory: newItem.subcategory,
                    finalPrice: newItem.finalPrice,
                    originalPrice: newItem.originalPrice,
                    rateCount: newItem.rateCount,
                    options: newItem.options,
                    path: newItem.path,
                    tag: newItem.tag,
                    tagline: newItem.tagline
                })
                .then(() => {
                    setAlertCont("New Suboption Added!!");
                    setAlertError(2);
                })
                .catch(() => {
                    setAlertCont("Failed to Add!!");
                    setAlertError(1);
                });
            }
            else {
                setAlertCont("Suboption already exists!!");
                setAlertError(1);
            }
            setTimeout(() => {
                setAlertError(0);
            }, 2000);
            handleClose();
        }

        
        const handleDeleteSuboption = (optName) => {
            let newItem = editItem;
            let opts = [];
            options.forEach((option) => {
                if(opt[0]===option[0]) {
                    let temp = [];
                    for(let i=0; i<option[1].length; i++) {
                        if(option[1][i]!==optName) {
                            temp.push(option[1][i]);
                        }
                    }
                    opts.push([option[0], temp]);
                }
                else {
                    opts.push(option);
                }
            });
            newItem.options = opts;

            httpClient.put('/allProductsData/' + itemId, {
                images: newItem.images,
                title: newItem.title,
                category: newItem.category,
                subcategory: newItem.subcategory,
                finalPrice: newItem.finalPrice,
                originalPrice: newItem.originalPrice,
                rateCount: newItem.rateCount,
                options: newItem.options,
                path: newItem.path,
                tag: newItem.tag,
                tagline: newItem.tagline
            })
            .then(() => {
                setAlertCont("Suboption Deleted Successfully!!");
                setAlertError(2);
            })
            .catch(() => {
                setAlertCont("Failed to Delete!!");
                setAlertError(1);
            });
            setTimeout(() => {
                setAlertError(0);
            }, 2000);
            handleClose();
        }
        
        return (
            <li>
                <div className="options-list-item">
                    <div className="title-div">
                        <p>{opt[0]}:</p>
                        <button className="dlt-main-button" onClick={handleDeleteOption}><DeleteIcon /></button>
                    </div>
                    <div className="list-item-options">
                        <ul>
                            {
                                opt[1] && opt[1].map((option, ind) => (
                                    <li key={ind}>
                                        <div className="list-item-option">
                                            <p>{option}</p>
                                            <button className="dlt-button" onClick={() => handleDeleteSuboption(option)}><DeleteIcon /></button>
                                        </div>
                                    </li>
                                ))
                            }
                            { (opt[1]===undefined || opt[1].length < 8) && 
                                <li>
                                    <form className="add-suboption-form" onSubmit={handleAddSuboption}>
                                        <input id="add-suboption-name" name="add-suboption-name" placeholder="Suboption Name" value={newSuboption} onChange={(e) => setNewSuboption(e.target.value)} required/>
                                        <button>Add</button>
                                    </form>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </li>
        )
    }

    
    return (
        <>
            { alertError > 0 ? 
                (<DocAlert alertCont={alertCont} alertError={alertError} />) : (
                    <Breadcrumbs className="breadcrumb">
                        <Link to="/" className="breadcrumb-active-link">Home</Link>
                        <Link to="/add-items">Options</Link>
                    </Breadcrumbs>
                )}

            <DocTitle title="Options"/>

            <section id="add-options">
                <div className="options-table">
                    <DataGrid
                        columns={columns}
                        rows={productsData}
                        components={{Toolbar: GridToolbar}}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10,25,50,100]}
                        sx={{
                            ".MuiDataGrid-columnHeaders" : {
                                backgroundColor: "var(--white-color-3)",
                                fontSize: "1.1em",
                            },
                            ".MuiDataGrid-virtualScroller" : {
                                backgroundColor: "var(--white-color-1)",
                                fontSize: "1.1em",
                            },
                            ".MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell": {
                                alignItems: "flex-start",
                                whiteSpace: "normal",
                                overflow: "auto",
                                padding: "5px"
                            }
                        }}
                        disableSelectionOnClick
                    />
                </div>

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div className="options-modal-updation-form">
                        <div className="space-bet-div">
                            <p>Item Details</p>
                            <div className="modal-close-icon-div">
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>

                        <div className="item-details">
                            <p><b>Name: </b>{itemName}</p>
                            <p><b>Category: </b>{category}</p>
                            <p><b>Subcategory: </b>{subcategory}</p>
                            <p><b>Price: </b>{salePrice} <del><em>{orgPrice}</em></del></p>

                            <div className="item-options">
                                <p><b>Options: </b></p>
                                <ul className="options-list">
                                    { options && options.map((opt, ind) => (
                                        <Option opt={opt} key={ind} item={editItem} />
                                    )) }
                                    { (options===undefined || options.length < 3) && 
                                        <li>
                                            <form className="add-option-form" onSubmit={handleAddOption}>
                                                <input id="add-option-name" name="add-option-name" placeholder="Option Name" value={newOption} onChange={(e) => setNewOption(e.target.value)} required/>
                                                <button>Add</button>
                                            </form>    
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </Modal>

            </section>
        </>
    )
};




export default AddOptions;