import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area, PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Breadcrumbs, IconButton, Tooltip as MUITooltip } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import DocTitle from "../components/DocTitle";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import httpClient from "../httpClient";
import commonContext from "../contexts/common/commonContext";


const SalesReport = () => {

    const navigate = useNavigate();
    const [years, setYears] = useState([]);
    const [yearSales, setYearSales] = useState([]);
    const [catSales, setCatSales] = useState([]);
    const [monthSales, setMonthSales] = useState([]);
    const [curYear, setCurYear] = useState("");
    const [curMonths, setCurMonths] = useState([]);
    const [curInd, setCurInd] = useState(0);
    const [modalData, setModalData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMonth, setModalMonth] = useState(false);

    const handleModalClose = () => setModalOpen(false);

    const { user } = useContext(commonContext);
    
    useEffect(() => {
        if(user===null) {
            navigate('/login/');
        } else {
            // TODO: check whether the real admin is present or not
        }
    }, [user, navigate]);

    useEffect(() => {
        let catSls = {};
        httpClient.get('/categoryData')
        .then(res => {
            res.data.forEach(cat => {
                catSls[cat.category] = {sales: 0, count: 0};
            })

            httpClient.get('/compOrdersData')
            .then(res => {
                const mapMonth = {0: "Jan", 1: "Feb", 2:"Mar", 3:"Apr", 4: "May", 5:"Jun", 6: "Jul", 7: "Aug", 8:"Sep", 9:"Oct", 10: "Nov", 11:"Dec"}
                let monthSls = {};
                let yearSls = {};
                let monthOrd = {};
                res.data.forEach(order => {
                    const date = new Date(order.date);
                    const mnth = mapMonth[date.getMonth()];
                    const yr = date.getFullYear();
                    for (let i=0; i<order.orders.length; i++) {
                        const item = order.orders[i];
                        let price = Number(item.price);
                        let quantity = Number(item.quantity)
                        catSls[item.category].sales += Number((price * quantity).toFixed(3));
                        catSls[item.category].count += quantity;
                        if(yearSls[yr]===undefined){
                            yearSls[yr] = 0;
                            monthSls[yr] = {Jan: 0, Feb: 0, Mar: 0, Apr: 0, May: 0, Jun: 0, Jul: 0, Aug: 0, Sep: 0, Oct: 0, Nov: 0, Dec: 0};
                            monthOrd[yr] = {Jan: {}, Feb: {}, Mar: {}, Apr: {}, May: {}, Jun: {}, Jul: {}, Aug: {}, Sep: {}, Oct: {}, Nov: {}, Dec: {}};
                        }
                        yearSls[yr] += Number((price * quantity).toFixed(3));
                        monthSls[yr][mnth] += Number((price * quantity).toFixed(3));
                        if(monthOrd[yr][mnth][item.category]===undefined) {
                            monthOrd[yr][mnth][item.category] = {quantity: 0, price: 0};
                        }
                        monthOrd[yr][mnth][item.category].quantity += quantity;
                        monthOrd[yr][mnth][item.category].price += Number((price * quantity).toFixed(3));
                    }
                });

                let tmp = [];
                for(let x in catSls) {
                    tmp.push({name: x, ...catSls[x]});
                }
                setCatSales(tmp);
                tmp = [];
                for(let x in monthSls) {
                    let t = [];
                    for(let m in monthSls[x]) {
                        t.push({month: m, sales: monthSls[x][m], orders: monthOrd[x][m]})
                    }
                    setCurMonths(t);
                    tmp.push({year: x, months: t});
                }
                // console.log(tmp);
                setMonthSales(tmp.slice(0));
                tmp = [];
                let yrs = [];
                for(let x in yearSls) {
                    tmp.push({year: x, sales: yearSls[x]});
                    yrs.push(x);
                }
                yrs.sort();
                setYearSales(tmp);
                setYears(yrs);
                setCurInd(yrs.length-1);
                setCurYear(yrs[yrs.length-1]);
            });
        })
    // eslint-disable-next-line
    }, [])


    const handlePrev = () => {
        for(let i=0; i < monthSales.length; i++) {
            if(monthSales[i].year===years[curInd-1]) {
                setCurMonths(monthSales[i].months);
                setCurYear(monthSales[i].year);
                break;
            }
        }
        setCurInd(curInd - 1);
    }
    
    const handleNext = () => {
        for(let i=0; i < monthSales.length; i++) {
            if(monthSales[i].year===years[curInd+1]) {
                setCurMonths(monthSales[i].months);
                setCurYear(monthSales[i].year);
                break;
            }
        }
        setCurInd(curInd + 1);
    }

    const handleBarClick = (e) => {
        for(let i=0; i<curMonths.length; i++) {
            if(curMonths[i].month===e.month) {
                let temp = [];
                const curCat = curMonths[i].orders;
                for(let cat in curCat) {
                    temp.push({name: cat, sales: curCat[cat].price, orders: curCat[cat].quantity});
                }
                setModalData(temp);
                setModalMonth(e.month);
                break;
            }
        }
        setModalOpen(true);
    }
    

    return (
        <div id="sales-report">
            <Breadcrumbs className="breadcrumb"><Link to="/">Home</Link></Breadcrumbs>
            <DocTitle title="Dashboard" />
            <div className="dashboard">
                <div className="chart">
                    <h2 className="chart-title">Category Sales</h2>
                    <ResponsiveContainer width="100%" height="80%">
                        <BarChart
                            data={catSales}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                            <Tooltip />
                            <Legend />
                            <Bar yAxisId="left" dataKey="sales" fill="#8884d8" />
                            <Bar yAxisId="right" dataKey="count" fill="#82ca9d" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                <div className="chart">
                    <h2 className="chart-title">Monthly Sales of {curYear}</h2>
                    <ResponsiveContainer width="100%" height="80%">
                        <BarChart
                            data={curMonths}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="sales" fill="#FF8042" onClick={handleBarClick} />
                        </BarChart>
                    </ResponsiveContainer>
                    <div className="chart-actions">
                        <IconButton disabled={curInd===0} onClick={handlePrev}>
                            <MUITooltip title="Previous Year">
                                <NavigateBeforeIcon />
                            </MUITooltip>
                        </IconButton>
                        <IconButton disabled={curInd===monthSales.length-1} onClick={handleNext}>
                            <MUITooltip title="Next Year">
                                <NavigateNextIcon />
                            </MUITooltip>
                        </IconButton>
                    </div>

                    <Modal
                        open={modalOpen}
                        onClose={handleModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className="view-chart-modal">
                            <div className="modal-chart-title">
                            <p>{modalMonth} {curYear}</p>
                            <IconButton onClick={handleModalClose}>
                                <CloseIcon />
                            </IconButton>
                            </div>
                            <h3>Category Sales & Counts</h3>
                            <div className="chart-modal">
                                <ResponsiveContainer width="90%" height="100%">
                                    <PieChart>
                                        <Pie
                                            dataKey="sales"
                                            isAnimationActive={true}
                                            data={modalData}
                                            cx="50%"
                                            cy="25%"
                                            outerRadius={80}
                                            fill="#8884d8"
                                            label
                                        />
                                        <Pie
                                            dataKey="orders"
                                            isAnimationActive={true}
                                            data={modalData}
                                            cx="50%"
                                            cy="70%"
                                            outerRadius={80}
                                            fill="#82ca9d"
                                            label
                                        />
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </Modal>
                </div>
                <div className="chart">
                    <h2 className="chart-title">Yearly Sales</h2>
                    <ResponsiveContainer width="100%" height="80%">
                        <AreaChart
                            data={yearSales}
                            margin={{
                                top: 10,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="year" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="sales" stroke="#8884d8" fill="#8884d8" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
}

export default SalesReport;
