import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useState, useEffect, useContext, useRef } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import DocTitle from "../components/DocTitle";
import DocAlert from "../components/DocAlert";
import { Breadcrumbs, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import commonContext from "../contexts/common/commonContext";
import ReactToPrint from "react-to-print";
import PrintIcon from '@mui/icons-material/Print';
import Invoice from "../components/Invoice";
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import httpClient from "../httpClient";
import { mobileNo } from "../data/constants";

const NewOrders = () => {
  const navigate = useNavigate();
  const { setInvoicesLen, setNewOrdersLen, invoicesLen, newOrdersLen, user } =
    useContext(commonContext);

    useEffect(() => {
        if(user===null) {
            navigate('/login/');
        }
    }, [user, navigate]);

  const [OrdersData, setOrdersData] = useState([]);
  const [FilteredData, setFilteredData] = useState([]);

  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");

  const [itemEdit, setItemEdit] = useState(false);
  const [viewItem, setViewItem] = useState(null);
  const [viewItemTotalQuantity, setViewItemTotalQuantity] = useState(0);
  const [viewItemTotalAmount, setViewItemTotalAmount] = useState(0);
  const [viewItemOrders, setViewItemOrders] = useState([]);

  const [alertCont, setAlertCont] = useState("");
  const [alertError, setAlertError] = useState(0);
  const [filtered, setFiltered] = useState(false);

  const [pageSize, setPageSize] = useState(10);

  const [invOpen, setInvOpen] = useState(false);
  const [newOrderModalOpen, setNewOrderModalOpen] = useState(false);

  const [categoryData, setCategoryData] = useState([]);
  const [userName, setUserName] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [newOrderDate, setNewOrderDate] = useState("");
  const [newOrders, setNewOrders] = useState([{pid: 100, name: "", category: "", price: 0, quantity: 0}]);
  const [newOrdersQuantity, setNewOrdersQuantity] = useState(0);
  const [newOrdersAmount, setNewOrdersAmount] = useState(0);
  const [adding, setAdding] = useState(false);

  const addEmptyOrder = () => {
    setNewOrders(prev => [...prev, {pid: 100, name: "", category: "", price: 0, quantity: 0}]);
  }

  const handleNewOrderModalOpen = () => {
    setNewOrderModalOpen(true);
    if (categoryData.length===0) {
      httpClient.get('/categoryData')
        .then(res => {
            // console.log(res);
            let tmp = [];
            if(res.data) {
                res.data.forEach((cat, index) => {
                    cat.srno = index+1;
                    tmp.push(cat);
                })
            }
            setCategoryData(tmp);
        });
    }
  }

  const handleInvOpen = () => setInvOpen(true);
  const handleInvClose = () => {
    setInvOpen(false);

    httpClient.put('/ordersData/' + viewItem.id, {
      date: viewItem.date,
      location: viewItem.location,
      name: viewItem.name,
      mobile: viewItem.mobile,
      address: viewItem.address,
      orders: viewItemOrders,
      type: viewItem.type,
      fop: viewItem.fop
    });
  };

  var compRef = useRef();

  const columns = [
    {
      field: "srno",
      headerName: "#",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "id",
      headerName: "Order ID",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Order Date",
      headerAlign: "center",
      align: "left",
      width: 200
    },
    {
      field: "total",
      headerName: "Total",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let amount = 0;
        for (let i=0; i<params.row.orders.length; i++) {
          amount += params.row.orders[i].price * params.row.orders[i].quantity;
        }
        return <div>{(amount).toFixed(2)}</div>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      headerAlign: "center",
      align: "center",
    },
    { field: "fop", headerName: "FOP", headerAlign: "center", align: "center" },
    {
      field: "view",
      headerName: "View",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className="table-view-button">
            <button onClick={() => handleView(params.row)}>View</button>
          </div>
        );
      },
    },
    {
      field: "process",
      headerName: "Process",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className="table-process-button">
            <button onClick={() => handleProcess(params.row)}>Process</button>
          </div>
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div className="table-delete-button">
            <button onClick={() => handleDelete(params.row.id)}>Cancel</button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    httpClient.get('/ordersData')
      .then(res => {
          let tmp = [];
          if(res.data) {
              [...res.data].reverse().forEach((ordr, index) => {
                  ordr.srno = index+1;
                  tmp.push(ordr);
              })
          }
          setOrdersData(tmp);
          setFilteredData(tmp);
      });
  }, [alertError]);

  useEffect(() => {
    let quantity = 0, amount = 0;
    for (let i=0; i < viewItemOrders.length; i++) {
      quantity += Number(viewItemOrders[i].quantity);
      amount += Number(viewItemOrders[i].price) * Number(viewItemOrders[i].quantity);
    }
    setViewItemTotalQuantity(quantity);
    setViewItemTotalAmount(amount);
  }, [viewItemOrders]);

  useEffect(() => {
    let quantity = 0, amount = 0;
    for (let i=0; i < newOrders.length; i++) {
      quantity += Number(newOrders[i].quantity);
      amount += Number(newOrders[i].price) * Number(newOrders[i].quantity);
    }
    setNewOrdersQuantity(quantity);
    setNewOrdersAmount(amount.toFixed(2));
  }, [newOrders]);

  const handleView = (item) => {
    handleInvOpen();
    setViewItem(item);
    setViewItemOrders(item.orders);
    setItemEdit(false);
  };

  const handleProcess = (item) => {
    httpClient.post('/invoicesData', item)
      .then(() => {
        setInvoicesLen(invoicesLen + 1);
        httpClient.delete('/ordersData/' + item.id)
          .then(() => {
            setNewOrdersLen(newOrdersLen - 1);
            setAlertCont("Order Processed Successfully!!");
            setAlertError(2);
          })
          .catch(() => {
            setAlertCont("Failed to Process!!");
            setAlertError(1);
          });
      })
      .catch(() => {
        setAlertCont("Failed to Process!!");
        setAlertError(1);
      });

    setTimeout(() => {
      setAlertError(0);
    }, 2000);
  };

  const handleDelete = (id) => {
    httpClient.delete('/ordersData/' + id)
      .then(() => {
        setNewOrdersLen(newOrdersLen - 1);
        setAlertCont("Order Cancelled Successfully!!");
        setAlertError(2);
      })
      .catch(() => {
        setAlertCont("Failed to Cancel!!");
        setAlertError(1);
      });

    setTimeout(() => {
      setAlertError(0);
    }, 2000);
  };

  const handleNewOrderSubmit = (e) => {
    e.preventDefault();

    let date = newOrderDate.split('T')[0];
    let time = newOrderDate.split('T')[1].split(':');
    let hh = Number(time[0]) % 12;

    let item = {
      date: `${date} ${hh}:${time[1]}:00 ${Number(time[0]) > 11? 'PM' : 'AM'}`,
      location: "Head Office",
      name: userName,
      mobile: mobile,
      address: address,
      orders: [...newOrders],
      type: "Delivery",
      fop: "Cash"
    }
    setAdding(true);
    httpClient.post('/ordersData', item)
      .then(() => {
        setNewOrdersLen(newOrdersLen + 1);
        setNewOrderModalOpen(false);
        setAlertCont("Added Successfully!!");
        setAlertError(2);
      })
      .catch(() => {
        setAlertCont("Failed to Process!!");
        setAlertError(1);
      })
      .finally(() => setAdding(false));

    setTimeout(() => {
      setAlertError(0);
    }, 2000);
  }

  const handleViewItemEdit = () => {
    setItemEdit(true); 
    setViewItemOrders(viewItem.orders);
  }

  const handleViewItemQuantityEdit = (index, value) => {
    const orders = [...viewItem.orders];
    orders[index].quantity = value===""? 0 : Number(value);
    setViewItemOrders(orders);
  }

  const handleViewItemRateEdit = (index, value) => {
    const orders = [...viewItem.orders];
    orders[index].price = value===""? 0 : Number(value);
    setViewItemOrders(orders);
  }

  const handleViewItemEditDone = () => {
    setItemEdit(false);
    
    httpClient.put('/ordersData/' + viewItem.id, {
      date: viewItem.date,
      location: viewItem.location,
      name: viewItem.name,
      mobile: viewItem.mobile,
      address: viewItem.address,
      orders: viewItemOrders,
      type: viewItem.type,
      fop: viewItem.fop
    });
  }

  const isValidDate = (date) => {
    let dateformat = /^\d{4}[-](0?[1-9]|1[0-2])[-](0?[1-9]|[1-2][0-9]|3[01])$/;

    if (date.match(dateformat)) {
      let operator = date.split("-");

      let datepart = [];
      if (operator.length > 1) {
        datepart = date.split("-");
      }
      let month = parseInt(datepart[0]);
      let day = parseInt(datepart[1]);
      let year = parseInt(datepart[2]);

      let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return false;
        }
      } else if (month === 2) {
        let leapYear = false;
        if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
        if (leapYear === false && day >= 29) return false;
        else {
          if (leapYear === true && day > 29) {
            console.log("Invalid date format!");
            return false;
          }
        }
      }
    } else {
      return false;
    }
    return true;
  };

  const isValidRange = (date1, date2) => {
    date1 = new Date(date1);
    date2 = new Date(date2);
    if (date1 > date2) return false;
    return true;
  };

  const isDateBetween = (date1, date2, date3) => {
    date1 = new Date(date1);
    date2 = new Date(date2);
    date3 = new Date(date3);
    if (date1 <= date2 && date2 <= date3) return true;
    return false;
  };

  const handleSearchForm = (e) => {
    e.preventDefault();
    if (!isValidDate(date1) || !isValidDate(date2)) {
      setAlertError(1);
      setAlertCont("Enter a valid date!!");
      setTimeout(() => {
        setAlertError(0);
      }, 2000);
    } else if (!isValidRange(date1, date2)) {
      setAlertError(1);
      setAlertCont("Dates should be in Chronological order!!");
      setTimeout(() => {
        setAlertError(0);
      }, 2000);
    } else {
      let tmp = [];
      for (let i = 0; i < OrdersData.length; i++) {
        const curDate = OrdersData[i].date.split(" ")[0];
        if (!isValidRange(date1, curDate)) break;
        if (isDateBetween(date1, curDate, date2)) {
          tmp.push(OrdersData[i]);
        }
      }
      setFilteredData(tmp);
      setFiltered(true);
    }
  };

  const handleClearSearch = () => {
    setFilteredData(OrdersData);
    setFiltered(false);
  };

  return (
    <>
      {alertError > 0 ? (
        <DocAlert alertCont={alertCont} alertError={alertError} />
      ) : (
        <Breadcrumbs className="breadcrumb">
          <Link to="/" className="breadcrumb-active-link">
            Home
          </Link>
          <Link to="/new-orders/">New Orders</Link>
        </Breadcrumbs>
      )}
      <DocTitle title="New Orders" />
      <section id="new-orders">
        <div className="search-form">
          <button onClick={handleNewOrderModalOpen}>Add new order</button>
        </div>
        <div className="search-form">
          <form onSubmit={handleSearchForm}>
            <input
              type="text"
              placeholder="FROM: YYYY-MM-DD"
              value={date1}
              onChange={(e) => setDate1(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="TO: YYYY-MM-DD"
              value={date2}
              onChange={(e) => setDate2(e.target.value)}
              required
            />
            <button
              type="submit"
              className="search-submit-btn"
              disabled={filtered ? true : false}
            >
              Search
            </button>
            {filtered && (
              <button onClick={handleClearSearch}>Clear Search</button>
            )}
          </form>
        </div>

        <div className="table-section">
          <div className="section-header">
            <p>View New Orders</p>
          </div>

          <div className="table-div">
            <div className="new-orders-table">
              <DataGrid
                columns={columns}
                rows={FilteredData}
                components={{ Toolbar: GridToolbar }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 25, 50, 100]}
                sx={{
                  ".MuiDataGrid-columnHeaders": {
                    backgroundColor: "var(--white-color-3)",
                    fontSize: "1.1em",
                  },
                  ".MuiDataGrid-virtualScroller": {
                    backgroundColor: "var(--white-color-1)",
                    fontSize: "1.1em",
                  },
                  ".MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight) > .MuiDataGrid-cell":
                    {
                      alignItems: "flex-start",
                      whiteSpace: "normal",
                      overflow: "auto",
                      padding: "5px",
                    },
                }}
                disableSelectionOnClick
              />
            </div>
          </div>
        </div>

        <Modal
          open={invOpen}
          onClose={handleInvClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="view-invoice-modal">
            <div className="space-bet-div">
              <p>Invoice</p>
              <div className="space-bet-div">
                <div className="edit-icon-div">
                  <IconButton onClick={!itemEdit? handleViewItemEdit : handleViewItemEditDone}>
                    {!itemEdit? (
                      <EditIcon className="edit-icon"/>
                    ):(
                      <DoneIcon />
                    )}
                  </IconButton>
                </div>
                { !itemEdit && 
                  <div id="print-content">
                    <ReactToPrint
                      trigger={() => (
                        <IconButton>
                          <PrintIcon />
                        </IconButton>
                      )}
                      content={() => compRef.current}
                    />
                    <div
                      style={{
                        position: "absolute",
                        height: "0",
                        width: "0",
                        display: "none",
                      }}
                    >
                      <div ref={compRef} id="view-inv">
                        <Invoice 
                          viewItem={viewItem} 
                          orders={viewItemOrders} 
                          totalQuantity={viewItemTotalQuantity} 
                          totalAmount={viewItemTotalAmount} 
                          user={user} 
                        />
                      </div>
                    </div>
                  </div>
                }
                <div className="modal-close-icon-div">
                  <IconButton onClick={handleInvClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </div>
            {viewItem && (
              <div className="view-invoice-content">
                <div className="invoice-header-img">
                  <img src="../../../../kumail_banner.png" alt="" />
                </div>
                <p className="tel">Tel: {mobileNo} | Head Office</p>
                <div className="invoice-content">
                  <div className="cust-details">
                    <div className="space-bet-div">
                      <h3>{viewItem.date.split(" ")[0]}</h3>
                      <h3>Order#{viewItem.id}</h3>
                    </div>
                    <h3>{viewItem.date.split(" ").slice(1).join(" ")}</h3>
                    <h3 className="div-head">Delivery</h3>
                    <h3>Name: {viewItem.name}</h3>
                    <h3>Mobile: {viewItem.mobile}</h3>
                    <h3>Address: {viewItem.address}</h3>
                  </div>
                  <div className="cust-details">
                    <div className="space-bet-div item-details">
                      <div>
                        <h3>ITEM</h3>
                      </div>
                      <div className="space-bet-div item-attr">
                        <h3>QTY</h3>
                        <h3>RATE</h3>
                        <h3>AMT</h3>
                      </div>
                    </div>
                  </div>
                  <div className="cust-details">
                    {viewItemOrders.map((order, index) => (
                      <div className="space-bet-div specific-item" key={index}>
                        <div className="specific-item-title">
                          <h3>{order.pid} - {order.name}</h3>
                        </div>
                        <div className="specific-item-attr">
                          {itemEdit? (
                              <div className="edit-input">
                                <input id={`quantityInput-${index}`} type="number" min="1" value={order.quantity} onChange={(e) => handleViewItemQuantityEdit(index, e.target.value)} required/>
                              </div>
                            ): (
                            <h3>{order.quantity}</h3>
                          )}
                          {itemEdit? (
                              <div className="edit-input">
                                <input id={`priceInput-${index}`} type="number" min="1" value={order.price} onChange={(e) => handleViewItemRateEdit(index, e.target.value)} required/>
                              </div>
                            ): (
                            <h3>{order.price}</h3>
                          )}
                          <h3>{(order.quantity * order.price).toFixed(2)}</h3>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="space-bet-div total-div">
                    <div>
                      <h3>NOS QTY: {viewItemTotalQuantity}</h3>
                    </div>
                    <div>
                      <h3>TOTAL: {viewItemTotalAmount.toFixed(2)} KWD</h3>
                    </div>
                  </div>
                  <div className="admin-div">
                    <h3>FOP: {viewItem.fop}</h3>
                    <h3>WAITER: {user}</h3>
                  </div>
                  <div className="cust-details thank-you-div">
                    <h3>Thank you Come Again!</h3>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
        
        <Modal
          open={newOrderModalOpen}
          onClose={() => setNewOrderModalOpen(false)}
          className="new-order-modal"
        >
          <div className="new-order-form">
            <form onSubmit={handleNewOrderSubmit} className="form" encType="multipart/form-data">
              <div className="form-group">
                <label htmlFor="user-name">Username</label>
                <input type="text" name="user-name" id="user-name" value={userName} placeholder="User Name" onChange={(e) => setUserName(e.target.value)} autoComplete="current-username" required/>
              </div>
              <div className="form-group">
                <label htmlFor="mobile">Mobile</label>
                <input type="text" name="mobile" id="mobile" value={mobile} placeholder="Mobile" onChange={(e) => {if (e.target.value.length <= 8) setMobile(e.target.value);}} autoComplete="mobile" required/>
              </div>
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <input type="datetime-local" name="date" id="date" value={newOrderDate} placeholder="Date" onChange={(e) => setNewOrderDate(e.target.value)} autoComplete="date" required/>
              </div>
              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input type="text" name="address" id="address" value={address} placeholder="Address" onChange={(e) => setAddress(e.target.value)} autoComplete="address" required/>
              </div>
              {newOrders.map((order, index) => (
                <div key={index}>
                  <p>Order {index+1}</p>
                  <div className="form-group">
                    <label htmlFor="item-id">Item id</label>
                    <input type="number" name="item-id" id="item-id" value={order.pid} placeholder="id" onChange={(e) => setNewOrders([...newOrders.slice(0,index), {...newOrders[index], pid: e.target.value}, ...newOrders.slice(index+1)])} autoComplete="item-id" required/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="item-name">Item name</label>
                    <input type="text" name="item-name" id="item-name" value={order.name} placeholder="name" onChange={(e) => setNewOrders([...newOrders.slice(0,index), {...newOrders[index], name: e.target.value}, ...newOrders.slice(index+1)])} autoComplete="item-name" required/>
                  </div>
                  <div className="form-group select-input-grp">
                    <label htmlFor="cat-name">Category</label>
                    <select id="cat-name" name="cat-name" value={order.category} onChange={(e) => setNewOrders([...newOrders.slice(0,index), {...newOrders[index], category: e.target.value}, ...newOrders.slice(index+1)])} required>
                        <option value="" disabled>Select Category</option>
                        {categoryData.map((item, k) => (
                            <option value={item.category} key={k}>{item.category}</option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="item-quantity">Item quantity</label>
                    <input type="number" name="item-quantity" id="item-quantity" value={order.quantity} placeholder="quantity" onChange={(e) => setNewOrders([...newOrders.slice(0,index), {...newOrders[index], quantity: e.target.value}, ...newOrders.slice(index+1)])} autoComplete="item-quantity" min={0} required/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="item-amount">Item price</label>
                    <input type="text" name="item-amount" id="item-amount" value={order.price} placeholder="price" onChange={(e) => setNewOrders([...newOrders.slice(0,index), {...newOrders[index], price: e.target.value}, ...newOrders.slice(index+1)])} autoComplete="item-amount" min={0} required/>
                  </div>
                  {(newOrders.length > 1) && (
                    <button className="delete-btn" onClick={() => {setNewOrders([...newOrders.slice(0,index), ...newOrders.slice(index+1)])}}>Delete</button>
                  )}
                </div>
              ))}
              <div>
                <p>Total Quantity: {newOrdersQuantity}</p>
                <p>Total Amount: {newOrdersAmount}</p>
                <button onClick={addEmptyOrder}>Add more</button>
              </div>
              { !adding && <button type="submit add-btn">Add</button>}
              { adding && <button type="submit" className="active-btn add-btn" disabled>Adding...</button>}
            </form>
          </div>
        </Modal>
      </section>
    </>
  );
};

export default NewOrders;
